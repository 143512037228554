
class AppActions {
  // constants here (Action Constants are always lowercase)
  Constants = {
    isLoading: "Show_Loader",
    setSmartAgroAppLayout: "user/setSmartAgroAppLayout",
  };
  showLoader = (payload: boolean) => ({
    type: this.Constants.isLoading,
    payload,
  });
  setSmartAgroAppLayout = (payload: boolean = true) => ({
    type: this.Constants.setSmartAgroAppLayout,
    payload: payload,
  });
}
export const app_Actions = new AppActions();
